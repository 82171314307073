$slider-height: $input-height;
$slider-margin-top: get-space(3);
$slider-font-family: $font-family-secondary;
$slider-background-color: $white !default;
$slider-rail-height: 4px !default;
$slider-border-radius: $slider-rail-height / 2;
$slider-thumb-height: 14px;
$slider-thumb-width: $slider-thumb-height;
$slider-thumb-margin-top: ($slider-thumb-height - $slider-rail-height) / -2;
$slider-thumb-margin-left: $slider-thumb-width / -2;
$slider-thumb-margin: $slider-thumb-margin-top 0 0 $slider-thumb-margin-left;
$slider-thumb-border-width: 2px;
$slider-label-color: #1c1c1c;
$slider-label-font-size: 15px;
$slider-label-background-color: transparent;
$slider-label-margin-y: get-space(3);
$slider-label-margin-x: 0px;
$slider-mark-width: 2px;
$slider-mark-height: $slider-rail-height;
$slider-mark-color: #fff;
$slider-rail-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
$slider-rail-color: #9d9d9d;
$slider-rail-opacity: 0.2;
$slider-value-label-min-width: 55px;
$slider-color-unknown: #dadada;

// Colours
$slider-color-primary: $electric-blue;
$slider-color-secondary: $green;

.slider {
    height: $slider-height;

    .MuiSlider-colorPrimary {
        color: $slider-color-primary;
    }

    &.unknown-value{
        .MuiSlider-colorPrimary {
            color: $slider-color-unknown;
        }

        .MuiSlider-rail,
        .MuiSlider-track
         {
            background-color: $slider-color-unknown;
            opacity: 1;
            box-shadow: none;
        }

        .MuiSlider-colorSecondary{
            color: $slider-color-unknown;
        }
    }

    .MuiSlider-colorSecondary {
        color: $slider-color-secondary;
    }

    &[data-showValueLabel="on"] {
        margin-top: $slider-margin-top;
    }

    .MuiSlider-thumb {
        height: $slider-thumb-height;
        width: $slider-thumb-width;
        background-color: $slider-background-color;
        border: $slider-thumb-border-width solid currentColor;
        margin: $slider-thumb-margin;
    }

    .MuiSlider-rail,
    .MuiSlider-track {
        height: $slider-rail-height;
        border-radius: $slider-border-radius;
    }

    .MuiSlider-rail {
        // opacity: $slider-rail-opacity;
        background-color: $slider-rail-color;
        box-shadow: $slider-rail-box-shadow;
    }

    .MuiSlider-mark {
        height: $slider-mark-height;
        width: $slider-mark-width;
        background-color: $slider-mark-color;
        border-radius: 0;
    }
}

.MuiSlider-valueLabel {
    margin: $slider-label-margin-y $slider-label-margin-x;

    & > span {
        background-color: $slider-label-background-color;
    }

    & > span > span {
        font-family: $slider-font-family;
        font-size: $slider-label-font-size;
        color: $slider-label-color;
    }
}

.slider__value-label {
    min-width: $slider-value-label-min-width;
}

.checkbox-slider{
    .unknown-note{
        font-size: 11px;
    }
}