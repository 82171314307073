.multi-input {
  &__controls {
    display: flex;
    flex-direction: row;
  }

  &__button {
    margin-left: get-space(3);
    margin-top: 2px;
  }
}
