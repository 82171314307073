$project-info-card-padding: get-space(4);
$project-info-card-margin: 0 0 get-space(3) 0;
$project-info-card-title-margin: 0 0 get-space(2) 0;
$project-info-card-title-max-width: 120px;
$project-info-card-metric-padding: 0 get-space(4) 0 get-space(3);
$project-info-card-metric-border-color: #001730;
$project-info-card-metric-border-opacity: 0.1;
$project-info-card-metric-border-left: 1px solid
  rgba($project-info-card-metric-border-color, $project-info-card-metric-border-opacity);
$project-info-card-metric-heading-margin: 0;
$project-info-card-metric-heading-font-size: 10px;
$project-info-card-metric-heading-letter-spacing: 0.5px;
$project-info-card-metric-heading-line-height: 14px;
$project-info-card-metric-heading-color: #1c1c1c;
$project-info-card-metric-heading-text-transform: uppercase;

.project-info-card {
  padding: $project-info-card-padding;
  margin: $project-info-card-margin;

  &__title {
    margin: $project-info-card-title-margin;
  }

  &__metric {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $project-info-card-metric-padding;
    border-left: $project-info-card-metric-border-left;
  }

  &__metric-heading {
    font-size: $project-info-card-metric-heading-font-size;
    letter-spacing: $project-info-card-metric-heading-letter-spacing;
    line-height: $project-info-card-metric-heading-line-height;
    margin: $project-info-card-metric-heading-margin;
    color: $project-info-card-metric-heading-color;
    text-transform: $project-info-card-metric-heading-text-transform;
  }
}
