$navigation-bg-color-primary: primary-accent("primary-dark-1") !default;
$navigation-bg-color-secondary: primary-accent("primary-dark-2") !default;
$navigation-bg-color-action-active: $navigation-bg-color-secondary !default;
$navigation-text-color-primary: $white !default;
$navigation-text-color-secondary: theme-color("secondary") !default;
$navigation-text-color-light: rgba($white, 0.5) !default;
$navigation-border-default: 1px solid rgba(255, 255, 255, 0.1) !default;

.navigation {
  display: flex;
  flex-direction: row;

  &__action {
    color: $white;
    display: block;
    @include border-radius(4px);
    .icon {
      color: $white;
      font-size: 1.6rem;
    }
    &--active {
      background-color: $navigation-bg-color-action-active;
    }
  }
  &__main {
    padding: (get-space(5)) get-space(2) get-space(2) get-space(2);
    background-color: $navigation-bg-color-primary;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  &__account {
    text-align: center;
  }

  &__actions {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__secondary {
    width: 200px;
    background-color: $navigation-bg-color-secondary;
    overflow-y: auto;
  }

  &__brand {
    text-align: center;
  }

  &__brand-logo {
    padding: get-space(5) get-space(4) get-space(4) get-space(4);
    &-img {
      height: 14px;
      width: auto;
      margin-bottom: get-space(1);
    }
  }

  &__brand-text {
    color: $navigation-text-color-primary;
    letter-spacing: 0.91px;
    line-height: 14px;
    display: block;
  }

  &__light-title {
    color: $navigation-text-color-light;
    margin-bottom: get-space(3);
    margin-left: get-space(3);
  }

  &__label {
    color: $navigation-text-color-light;
    &--highlight {
      color: $navigation-text-color-secondary;
    }
  }

  &__back {
    margin-left: -6px;
  }

  &__link {
    display: flex;
    align-items: center;
    color: $navigation-text-color-primary;
    text-decoration: none;
    margin-bottom: get-space(1);
    padding: get-space(2) get-space(3);
    @include border-radius(4px);
    @include transition(background-color 300ms ease-in);

    .icon {
      color: $navigation-text-color-primary;
    }

    &:hover,
    &--active {
      cursor: pointer;
      background-color: $navigation-bg-color-primary;
    }
  }
}

.navigation-view {
  &__action {
    &--content {
      position: relative;
      margin: 0 auto;
      &:after {
        content: "";
        position: absolute;
        bottom: -(get-space(3));
        left: 0px;
        width: 100%;
        height: 1px;
        background-color: rgba($white, 0.2);
      }
    }
  }
}
