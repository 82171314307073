/*!
 * froala_editor v3.1.0 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2020 Froala Labs
 */

.clearfix::after {
    clear: both;
    display: block;
    content: "";
    height: 0;
}

.hide-by-clipping {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

img.fr-rounded,
.fr-img-caption.fr-rounded img {
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}

img.fr-bordered,
.fr-img-caption.fr-bordered img {
    border: solid 5px #ccc;
}

img.fr-bordered {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

.fr-img-caption.fr-bordered img {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.fr-view {
    word-wrap: break-word;
}
.fr-view span[style~="color:"] a {
    color: inherit;
}
.fr-view strong {
    font-weight: 700;
}
.fr-view table {
    border: none;
    border-collapse: collapse;
    empty-cells: show;
    max-width: 100%;
}
.fr-view table td {
    min-width: 5px;
}
.fr-view table.fr-dashed-borders td,
.fr-view table.fr-dashed-borders th {
    border-style: dashed;
}
.fr-view table.fr-alternate-rows tbody tr:nth-child(2n) {
    background: whitesmoke;
}
.fr-view table td,
.fr-view table th {
    border: 1px solid #ddd;
}
.fr-view table td:empty,
.fr-view table th:empty {
    height: 20px;
}
.fr-view table td.fr-highlighted,
.fr-view table th.fr-highlighted {
    border: 1px double red;
}
.fr-view table td.fr-thick,
.fr-view table th.fr-thick {
    border-width: 2px;
}
.fr-view table th {
    background: #ececec;
}
.fr-view hr {
    clear: both;
    user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    break-after: always;
    page-break-after: always;
}
.fr-view .fr-file {
    position: relative;
}
.fr-view .fr-file::after {
    position: relative;
    content: "\1F4CE";
    font-weight: normal;
}
.fr-view pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: visible;
}
.fr-view[dir="rtl"] blockquote {
    border-left: none;
    border-right: solid 2px #5e35b1;
    margin-right: 0;
    padding-right: 5px;
    padding-left: 0;
}
.fr-view[dir="rtl"] blockquote blockquote {
    border-color: #00bcd4;
}
.fr-view[dir="rtl"] blockquote blockquote blockquote {
    border-color: #43a047;
}
.fr-view blockquote {
    border-left: solid 2px #5e35b1;
    margin-left: 0;
    padding-left: 5px;
    color: #5e35b1;
}
.fr-view blockquote blockquote {
    border-color: #00bcd4;
    color: #00bcd4;
}
.fr-view blockquote blockquote blockquote {
    border-color: #43a047;
    color: #43a047;
}
.fr-view span.fr-emoticon {
    font-weight: normal;
    font-family: "Apple Color Emoji", "Segoe UI Emoji", "NotoColorEmoji", "Segoe UI Symbol", "Android Emoji",
        "EmojiSymbols";
    display: inline;
    line-height: 0;
}
.fr-view span.fr-emoticon.fr-emoticon-img {
    background-repeat: no-repeat !important;
    font-size: inherit;
    height: 1em;
    width: 1em;
    min-height: 20px;
    min-width: 20px;
    display: inline-block;
    margin: -0.1em 0.1em 0.1em;
    line-height: 1;
    vertical-align: middle;
}
.fr-view .fr-text-gray {
    color: #aaa !important;
}
.fr-view .fr-text-bordered {
    border-top: solid 1px #222;
    border-bottom: solid 1px #222;
    padding: 10px 0;
}
.fr-view .fr-text-spaced {
    letter-spacing: 1px;
}
.fr-view .fr-text-uppercase {
    text-transform: uppercase;
}
.fr-view .fr-class-highlighted {
    background-color: #ffff00;
}
.fr-view .fr-class-code {
    border-color: #cccccc;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    background: #f5f5f5;
    padding: 10px;
    font-family: "Courier New", Courier, monospace;
}
.fr-view .fr-class-transparency {
    opacity: 0.5;
}
.fr-view img {
    position: relative;
    max-width: 100%;
}
.fr-view img.fr-dib {
    margin: 5px auto;
    display: block;
    float: none;
    vertical-align: top;
}
.fr-view img.fr-dib.fr-fil {
    margin-left: 0;
    text-align: left;
}
.fr-view img.fr-dib.fr-fir {
    margin-right: 0;
    text-align: right;
}
.fr-view img.fr-dii {
    display: inline-block;
    float: none;
    vertical-align: bottom;
    margin-left: 5px;
    margin-right: 5px;
    max-width: calc(100% - (2 * 5px));
}
.fr-view img.fr-dii.fr-fil {
    float: left;
    margin: 5px 5px 5px 0;
    max-width: calc(100% - 5px);
}
.fr-view img.fr-dii.fr-fir {
    float: right;
    margin: 5px 0 5px 5px;
    max-width: calc(100% - 5px);
}
.fr-view span.fr-img-caption {
    position: relative;
    max-width: 100%;
}
.fr-view span.fr-img-caption.fr-dib {
    margin: 5px auto;
    display: block;
    float: none;
    vertical-align: top;
}
.fr-view span.fr-img-caption.fr-dib.fr-fil {
    margin-left: 0;
    text-align: left;
}
.fr-view span.fr-img-caption.fr-dib.fr-fir {
    margin-right: 0;
    text-align: right;
}
.fr-view span.fr-img-caption.fr-dii {
    display: inline-block;
    float: none;
    vertical-align: bottom;
    margin-left: 5px;
    margin-right: 5px;
    max-width: calc(100% - (2 * 5px));
}
.fr-view span.fr-img-caption.fr-dii.fr-fil {
    float: left;
    margin: 5px 5px 5px 0;
    max-width: calc(100% - 5px);
}
.fr-view span.fr-img-caption.fr-dii.fr-fir {
    float: right;
    margin: 5px 0 5px 5px;
    max-width: calc(100% - 5px);
}
.fr-view .fr-video {
    text-align: center;
    position: relative;
}
.fr-view .fr-video.fr-rv {
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}
.fr-view .fr-video.fr-rv > iframe,
.fr-view .fr-video.fr-rv object,
.fr-view .fr-video.fr-rv embed {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.fr-view .fr-video > * {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    max-width: 100%;
    border: none;
}
.fr-view .fr-video.fr-dvb {
    display: block;
    clear: both;
}
.fr-view .fr-video.fr-dvb.fr-fvl {
    text-align: left;
}
.fr-view .fr-video.fr-dvb.fr-fvr {
    text-align: right;
}
.fr-view .fr-video.fr-dvi {
    display: inline-block;
}
.fr-view .fr-video.fr-dvi.fr-fvl {
    float: left;
}
.fr-view .fr-video.fr-dvi.fr-fvr {
    float: right;
}
.fr-view a.fr-strong {
    font-weight: 700;
}
.fr-view a.fr-green {
    color: green;
}
.fr-view .fr-img-caption {
    text-align: center;
}
.fr-view .fr-img-caption .fr-img-wrap {
    padding: 0;
    margin: auto;
    text-align: center;
    width: 100%;
}
.fr-view .fr-img-caption .fr-img-wrap img {
    display: block;
    margin: auto;
    width: 100%;
}
.fr-view .fr-img-caption .fr-img-wrap > span {
    margin: auto;
    display: block;
    padding: 5px 5px 10px;
    font-size: 14px;
    font-weight: initial;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-opacity: 0.9;
    -moz-opacity: 0.9;
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    width: 100%;
    text-align: center;
}
.fr-view button.fr-rounded,
.fr-view input.fr-rounded,
.fr-view textarea.fr-rounded {
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}
.fr-view button.fr-large,
.fr-view input.fr-large,
.fr-view textarea.fr-large {
    font-size: 24px;
}

/**
   * Image style.
   */
a.fr-view.fr-strong {
    font-weight: 700;
}
a.fr-view.fr-green {
    color: green;
}

/**
   * Link style.
   */
img.fr-view {
    position: relative;
    max-width: 100%;
}
img.fr-view.fr-dib {
    margin: 5px auto;
    display: block;
    float: none;
    vertical-align: top;
}
img.fr-view.fr-dib.fr-fil {
    margin-left: 0;
    text-align: left;
}
img.fr-view.fr-dib.fr-fir {
    margin-right: 0;
    text-align: right;
}
img.fr-view.fr-dii {
    display: inline-block;
    float: none;
    vertical-align: bottom;
    margin-left: 5px;
    margin-right: 5px;
    max-width: calc(100% - (2 * 5px));
}
img.fr-view.fr-dii.fr-fil {
    float: left;
    margin: 5px 5px 5px 0;
    max-width: calc(100% - 5px);
}
img.fr-view.fr-dii.fr-fir {
    float: right;
    margin: 5px 0 5px 5px;
    max-width: calc(100% - 5px);
}

span.fr-img-caption.fr-view {
    position: relative;
    max-width: 100%;
}
span.fr-img-caption.fr-view.fr-dib {
    margin: 5px auto;
    display: block;
    float: none;
    vertical-align: top;
}
span.fr-img-caption.fr-view.fr-dib.fr-fil {
    margin-left: 0;
    text-align: left;
}
span.fr-img-caption.fr-view.fr-dib.fr-fir {
    margin-right: 0;
    text-align: right;
}
span.fr-img-caption.fr-view.fr-dii {
    display: inline-block;
    float: none;
    vertical-align: bottom;
    margin-left: 5px;
    margin-right: 5px;
    max-width: calc(100% - (2 * 5px));
}
span.fr-img-caption.fr-view.fr-dii.fr-fil {
    float: left;
    margin: 5px 5px 5px 0;
    max-width: calc(100% - 5px);
}
span.fr-img-caption.fr-view.fr-dii.fr-fir {
    float: right;
    margin: 5px 0 5px 5px;
    max-width: calc(100% - 5px);
}
