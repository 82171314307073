.divTable {
    display: flex;
    flex-direction: column;
    border-collapse: collapse;
    width: 100%;
}

.divTableRow {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    @include border-bottom();
    @include transition();

    &:first-child {
        @include border-y();
    }

    &:hover {
        cursor: pointer;
        background-color: $table-row-hover-background-color;
    }
    &:active {
        @include border-y($table-row-hover-border);
        background-color: $table-row-active-background-color;
    }
}

.divTableHeading {
    display: flex;
    flex-direction: row;
}

.divTableCell,
.divTableHead {
    padding: get-space(3);
    display: block;
    flex: 1;

    &.table-cell--action {
        flex: unset;
        width: 30px;
        padding: 0px;
        .tooltip {
            line-height: 40px;
            vertical-align: middle;
        }
    }
}

.divTableHeading {
    display: flex;
    flex-direction: row;
    .divTableRow {
        width: 100%;
    }
}

.divTableFoot {
    display: flex;
    flex-direction: row;
    .divTableRow {
        width: 100%;
    }
}

.divTableBody {
    display: flex;
    flex-direction: column;
}
