// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: get-space(3);
$btn-padding-x: get-space(4);
$btn-font-family: $primary-font !default;
$btn-font-size: 0.85rem !default;
$btn-line-height: 1rem !default;

$btn-focus-width: 0.2rem !default;
$btn-focus-color: rgba($primary, 0.25) !default;
$btn-focus-box-shadow: 0 0 0 $btn-focus-width $btn-focus-color !default;

$btn-padding-y-xs: get-space(1) !default;
$btn-padding-x-xs: get-space(3) !default;

$btn-padding-y-sm: get-space(2) !default;
$btn-padding-x-sm: get-space(4) !default;

$btn-padding-y-lg: get-space(4) !default;
$btn-padding-x-lg: get-space(5) !default;

$btn-sizes: () !default;

$btn-sizes: map-merge(
  (
    "xs": (
      "padding-y": $btn-padding-y-xs,
      "padding-x": $btn-padding-x-xs,
      "fontSize": $font-size-sm,
      "lineHeight": $font-size-sm * 2,
      "height": 28px
    ),
    "sm": (
      "padding-y": $btn-padding-y-sm,
      "padding-x": $btn-padding-x-sm,
      "fontSize": $font-size-sm,
      "lineHeight": $font-size-sm * 2,
      "height": 36px
    ),
    "md": (
      "padding-y": $btn-padding-y,
      "padding-x": $btn-padding-x,
      "fontSize": $font-size-base,
      "lineHeight": 44px,
      "height": 44px
    ),
    "lg": (
      "padding-y": $btn-padding-y-lg,
      "padding-x": $btn-padding-x-lg,
      "fontSize": $font-size-lg,
      "lineHeight": 60px,
      "height": 60px
    )
  ),
  $btn-sizes
);

$btn-border-width: 1px !default;

$btn-font-weight: bold !default;
$btn-box-shadow: inset 0 1px 0 rgba($white, 0.15), 0 1px 1px rgba($black, 0.075) !default;
$btn-text-transform: uppercase;
$btn-focus-width: 0.2rem !default;
$btn-focus-box-shadow: 0 0 0 $btn-focus-width rgba($black, 0.075) !default;
$btn-disabled-opacity: 0.65 !default;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125) !default;

$btn-link-disabled-color: $gray-500 !default;

$btn-block-spacing-y: 0.5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: get-space(1) !default;
$btn-border-radius-xs: get-space(1) !default;
$btn-border-radius-sm: get-space(1) !default;
$btn-border-radius-lg: get-space(1) !default;

$font-weight-normal: 400;
$link-decoration: none;

$link-color: theme-color("dark-blue");
$link-hover-color: theme-color(tertiary);
$link-hover-decoration: none;

$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out !default;
$button-icon-sizes: (
  xxs: (
    get-space(4) + get-space(1)
  ),
  xs: (
    get-space(4) + get-space(2)
  ),
  sm: (
    get-space(4) + (get-space(2) * 2)
  ),
  md: (
    get-space(4) + (get-space(3) * 2)
  ),
  lg: (
    get-space(4) * 3
  )
);
//
// Base styles
//

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  letter-spacing: 1px;
  color: $body-color;
  border-radius: $btn-border-radius;
  font-weight: lighter;
  user-select: none;
  background-color: transparent;
  text-transform: $btn-text-transform;
  border: $btn-border-width solid transparent;
  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
  @include transition($btn-transition);

  @include hover {
    color: $body-color;
    text-decoration: none;
    cursor: pointer;
  }

  &:focus,
  &.focus {
    outline: 0;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
    cursor: not-allowed;
    @include box-shadow(none);
  }

  // &:not(:disabled):not(.disabled):active,
  // &:not(:disabled):not(.disabled).active {
  //     @include box-shadow($btn-active-box-shadow);

  //     &:focus {
  //         @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
  //     }
  // }

  &--icon {
    padding: 0px !important;

    &.btn {
      line-height: 100%;
      &--block {
        height: nth(map-get($map: $button-icon-sizes, $key: md), 1);
      }
      @each $name, $values in $button-icon-sizes {
        &--#{$name} {
          width: nth($values, 1);
          height: nth($values, 1);
        }
      }
    }
  }

  &--shape-none {
    background-color: transparent;
    border: none;
    box-shadow: none !important;

    padding: 0px !important;
    &:hover {
      background-color: transparent !important;
      color: blue;
    }
  }

  &--shape-circle {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }
}

a.btn {
  text-decoration: none;
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

//
// Alternate buttons
//

@each $color, $value in $theme-colors {
  .btn--#{$color} {
    $hover: theme-color("tertiary");
    $active: theme-color("dark");
    @if ($color == "primary" or $color == "secondary" or $color== "negative") {
      $hover: theme-color("tertiary");
      $active: theme-color("dark");
    } @else {
      @if (is-dark-color($value)) {
        $hover: lighten($value, 10%);
        $active: lighten($value, 20%);
      } @else {
        $hover: darken($value, 10%);
        $active: darken($value, 20%);
      }
    }
    @include button-variant($value, $value, $hover, $hover, $active, $active);
  }
}

@each $color, $value in $theme-colors {
  .btn--outline-#{$color} {
    @include button-outline-variant($value);
  }
}

//
// Link buttons
//

// Make a button look and behave like a link
.btn--link,
.btn--link-big {
  font-weight: $font-weight-normal;
  color: $link-color;
  text-decoration: $link-decoration;
  font-weight: normal;

  @include hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &:focus,
  &.focus {
    text-decoration: $link-hover-decoration;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  }

  // No need for an active state here
}
.btn--link-big {
  text-transform: none;
  font-family: $secondary-font;
  font-weight: bold;
  letter-spacing: -0.5px;
}

//
// Button Sizes
//

@each $sizeLabel, $sizeObj in $btn-sizes {
  .btn--#{$sizeLabel} {
    height: map-get($sizeObj, "height");
    @include button-size(
      0,
      map-get($sizeObj, "padding-x"),
      map-get($sizeObj, "fontSize"),
      map-get($sizeObj, "lineHeight"),
      $btn-border-radius-lg
    );
  }
}

//
// Block button
//

.btn-block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  + .btn-block {
    margin-top: $btn-block-spacing-y;
  }
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}
