$white: #fff !default;
$gray-100: #edeff2 !default;
$gray-200: #dadada !default;
$gray-300: #9d9d9d !default;
$gray-400: #737373 !default;
$gray-500: #1e1e1e !default;
$black: #000 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500
  ),
  $grays
);

$gray-accents: () !default;
$gray-accents: map-merge(
  (
    "gray-100": $gray-100,
    "gray-200": $gray-200,
    "gray-300": $gray-300,
    "gray-400": $gray-400,
    "gray-500": $gray-500,
    "black": $black
  ),
  $gray-accents
);

$dark-blue: #001730 !default;
$blue: #00135d !default;
$electric-blue: #0022a4;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #ff0201 !default;
$orange: #fd7e14 !default;
$yellow: #f9ab1e !default;
$green: #1ac541 !default;
$teal: #5df4ee !default;
$cyan: #17a2b8 !default;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "dark-blue": $dark-blue,
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-300,
    "gray-dark": $gray-500
  ),
  $colors
);

$primary: $blue !default;
$secondary: $teal !default;
$tertiary: $electric-blue !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-500 !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "tertiary": $tertiary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
    "white": $white,
    "dark-blue": $dark-blue
  ),
  $theme-colors
);
// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-500 !default;
$yiq-text-light: $white !default;

$primary-accents: () !default;
$primary-accents: map-merge(
  (
    "primary-dark-1": #001730,
    "primary-dark-2": #0f2640,
    "primary-dark-3": #4b6684,
    "primary-light-1": #3454d1,
    "primary-light-2": #5f77d5,
    "primary-light-3": #8c9bd3,
    "primary-light-4": #8c9bd3,
    "primary-light-5": #e9ebf7,
    "primary-light-6": #f4f5fb
  ),
  $primary-accents
);

$secondary-accents: () !default;
$secondary-accents: map-merge(
  (
    "secondary-accent-1": #7df6f1,
    "secondary-accent-2": #9ef8f5,
    "secondary-accent-3": #befbf8,
    "secondary-accent-4": #dffdfc
  ),
  $secondary-accents
);

$indicator-accents: () !default;
$indicator-accents: map-merge(
  (
    "indicator-accent-1": #6ca9ba,
    "indicator-accent-2": #187894,
    "indicator-accent-3": #0e4251
  ),
  $indicator-accents
);
