@mixin border-y($border-style: $base-border) {
    border-top: $border-style;
    border-bottom: $border-style;
}

@mixin border-x($border-style: $base-border) {
    border-left: $border-style;
    border-right: $border-style;
}

@mixin border-top($border-style: $base-border) {
    border-top: $border-style;
}

@mixin border-bottom($border-style: $base-border) {
    border-bottom: $border-style;
}

@mixin border-left($border-style: $base-border) {
    border-left: $border-style;
}

@mixin border-right($border-style: $base-border) {
    border-right: $border-style;
}
