.toaster {
  min-height: 60px;
  max-height: 120px;
  width: 250px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    top: 120px;
    left: 0px;
    width: 100%;
    height: 1px;
    -webkit-box-shadow: 0px -7px 23px 23px rgba(255, 255, 255, 1);
    -moz-box-shadow: 0px -7px 23px 23px rgba(255, 255, 255, 1);
    box-shadow: 0px -7px 23px 23px rgba(255, 255, 255, 1);
  }

  &__actions {
    height: 0px;
    width: 100%;
    position: absolute;
    right: 0px;
    top: 0px;
  }

  &__content {
    flex: 1;
    width: 100%;
    margin-top: get-space(3);
  }
}

.toaster-service {
  position: fixed;
  bottom: 0px;
  right: 0px;
  display: flex;
  flex-direction: column-reverse;
  z-index: 99999999;
}
