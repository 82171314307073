$tooltip-triangle-widths: 8px 8px 8px !default;
$tooltip-padding: get-space(3);

.tippy-popper {
  [x-circle] {
    background-color: transparent !important;
  }
  .tippy-tooltip {
    padding: $tooltip-padding;
    background-color: transparent;
  }
}

.tooltip {
  position: relative;
  display: inline-flex;

  &:focus {
    outline: none;
  }

  &__content {
    display: none;
    position: absolute;
    height: auto;
    vertical-align: middle;
    z-index: 10000;
  }

  & > .tooltip__element {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    @include user-select();
  }
  &.trigger-on-hover:hover {
    & > .tooltip__content {
      display: block !important;
    }
  }

  &.trigger-on-click {
    cursor: pointer;

    &[data-visible="true"] {
      & > .tooltip__content {
        display: block !important;
      }
    }
  }

  // TODO: This might need to be revisited to make more generi
  &.top-right-side {
    .tooltip__content {
      bottom: 50%;
      left: 100%;
      transform: translateY(12.5%);
    }
    &.has-arrow {
      & > .tooltip__content {
        margin-left: 20px;
        &:after {
          content: " ";
          position: absolute;
          right: 100%;
          bottom: 5px;
          margin-top: -(nth($tooltip-triangle-widths, 1)) - 0px;
          width: 0;
          height: 0;
          border-top: nth($tooltip-triangle-widths, 1) solid transparent;
          border-bottom: nth($tooltip-triangle-widths, 1) solid transparent;
          border-right: (nth($tooltip-triangle-widths, 1)) solid $white;
        }
      }
    }
  }

  &.top-side {
    .tooltip__content {
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
    &.has-arrow {
      & > .tooltip__content {
        margin-bottom: 10px;
        &:after {
          content: " ";
          position: absolute;
          top: 100%;
          left: 50%;
          margin-left: -(nth($tooltip-triangle-widths, 1)) - 0px;
          width: 0;
          height: 0;
          border-left: nth($tooltip-triangle-widths, 1) solid transparent;
          border-right: nth($tooltip-triangle-widths, 1) solid transparent;
        }
      }
    }
  }

  &.bottom-side {
    .tooltip__content {
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }

    &.has-arrow {
      & > .tooltip__content {
        margin-top: 10px;
        &:after {
          content: " ";
          position: absolute;
          bottom: 100%;
          left: 50%;
          margin-left: -(nth($tooltip-triangle-widths, 1)) - 0px;
          width: 0;
          height: 0;
          border-left: nth($tooltip-triangle-widths, 1) solid transparent;
          border-right: nth($tooltip-triangle-widths, 1) solid transparent;
        }
      }
    }
  }

  &.bottom-right-side {
    .tooltip__content {
      top: 100%;
      left: 50%;
      transform: translateX(-90%);
    }

    &.has-arrow {
      & > .tooltip__content {
        margin-top: 10px;
        &:after {
          content: " ";
          position: absolute;
          bottom: 100%;
          left: 50%;
          margin-left: -(nth($tooltip-triangle-widths, 1)) - 0px;
          width: 0;
          height: 0;
          border-left: nth($tooltip-triangle-widths, 1) solid transparent;
          border-right: nth($tooltip-triangle-widths, 1) solid transparent;
        }
      }
    }
  }

  &.bottom-left-side {
    .tooltip__content {
      top: 100%;
      left: 50%;
      transform: translateX(-30%);
    }

    &.has-arrow {
      & > .tooltip__content {
        margin-top: 10px;
        &:after {
          content: " ";
          position: absolute;
          bottom: 100%;
          left: 50%;
          margin-left: -(nth($tooltip-triangle-widths, 1)) - 0px;
          width: 0;
          height: 0;
          border-left: nth($tooltip-triangle-widths, 1) solid transparent;
          border-right: nth($tooltip-triangle-widths, 1) solid transparent;
        }
      }
    }
  }

  &.right-side {
    .tooltip__content {
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      width: auto;
      margin-left: 0px;
    }

    &.has-arrow {
      & > .tooltip__content {
        margin-left: 10px;
        &:after {
          content: " ";
          position: absolute;
          right: 100%;
          top: 50%;
          margin-top: -(nth($tooltip-triangle-widths, 1)) - 0px;
          width: 0;
          height: 0;
          border-top: nth($tooltip-triangle-widths, 1) solid transparent;
          border-bottom: nth($tooltip-triangle-widths, 1) solid transparent;
        }
      }
    }
  }

  &.left-side {
    .tooltip__content {
      top: 50%;
      right: 100%;
      transform: translateY(-50%);
    }

    &.has-arrow {
      & > .tooltip__content {
        margin-right: 10px;
        &:after {
          content: " ";
          position: absolute;
          left: 100%;
          top: 50%;
          margin-top: -(nth($tooltip-triangle-widths, 1)) - 0px;
          width: 0;
          height: 0;
          border-top: nth($tooltip-triangle-widths, 1) solid transparent;
          border-bottom: nth($tooltip-triangle-widths, 1) solid transparent;
        }
      }
    }
  }
}

.tooltip-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background-color: #8c9bd3;
  color: #fff;
}

.tooltip-icon__text {
  line-height: 1em;
  height: 1em;
}
