@import "./reactdates";

.SingleDatePicker {
    width: 100%;
}

.SingleDatePickerInput {
    border: none;
    width: 100%;
    .DateInput {
        width: 100%;
        &_input {
            @extend .input;
            width: 100%;
            background-color: transparent;
        }

        &_fang {
            margin-top: 1px;
        }
    }
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: theme-color();
    border: 1px solid theme-color();
    color: $white;
}

.DayPicker_weekHeader {
    transform: translateY(-8px);
}