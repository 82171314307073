$radio-button-font-size: $font-size-base !default;
$radio-button-padding-left: $input-padding-x !default;
$radio-button-padding-right: $input-padding-x !default;
$radio-button-height: $input-height !default;
$radio-button-input-margin: 0 get-space(2) 0 0 !default;
$radio-button-border-width: $input-border-width !default;
$radio-button-border-color-focus: $input-border-color-focus !default;
$radio-button-transition: $input-transition !default;
$radio-button-border-radius: $input-border-radius !default;
$radio-button-fill-container-margin-x: get-space(1) !default;
$radio-button-list-margin-x: -1 * $radio-button-fill-container-margin-x !default;

.radio-button {
  border: $radio-button-border-width solid $input-border-color;
  padding-left: $radio-button-padding-left;
  padding-right: $radio-button-padding-right;
  font-size: $radio-button-font-size;
  height: $radio-button-height;
  display: flex;
  align-items: center;
  background-color: $white;
  @include border-radius($radio-button-border-radius);
  @include transition($radio-button-transition);
  @include hover {
    border: $radio-button-border-width solid $radio-button-border-color-focus;
    cursor: pointer;
  }

  &--selected {
    border: $radio-button-border-width solid $radio-button-border-color-focus;
  }

  &--fill-container {
    flex: 1;
    margin: 0 $radio-button-fill-container-margin-x;
  }

  input {
    margin: $radio-button-input-margin;
  }
}

.radio-button-list {
  display: flex;
  padding-left: 0px;
  margin: 0 $radio-button-list-margin-x;

  &--disabled {
    opacity: 0.5;
  }
}
