$table-bg: $white !default;
$table-cell-padding: get-space(2) !default;
$table-border-width: 1px !default;
$table-border-color: $gray-300 !default;
$table-cell-padding-sm: get-space(2) !default;
$table-accent-bg: $gray-100 !default;
$table-hover-bg: $gray-500 !default;
$table-active-bg: $white !default;
$table-dark-bg: theme-color("dark") !default;
$table-dark-color: theme-color("dark") !default;
$table-dark-border-color: $gray-100 !default;
$table-head-color: $white !default;
$table-head-bg: $white !default;
$table-dark-accent-bg: $gray-500 !default;
$table-dark-hover-bg: $gray-500 !default;

$table-row-hover-border-width: 1px !default;
$table-row-hover-border-color: theme-color() !default;
$table-row-hover-border: $table-row-hover-border-width solid $table-row-hover-border-color !default;

$table-row-hover-background-color: get-accent("light", 6) !default;
$table-row-active-background-color: get-accent("light", 5) !default;
//
// Basic Bootstrap table
//
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: $spacer;
  background-color: $table-bg; // Reset for nesting within parents with `background-color`.
  border-spacing: 0px;
  border-collapse: collapse;
  &-condensed {
    td {
      padding: 0.25rem 0.75rem;
    }
  }

  &__input {
    margin-left: 0px;
  }
  th,
  td {
    padding: get-space(3);
    vertical-align: top;
    // border-top: $table-border-width solid $table-border-color;
  }

  tbody + tbody {
    // border-top: (2 * $table-border-width) solid $table-border-color;
  }

  thead th {
    vertical-align: bottom;
    text-align: left;
    font-weight: bold;
    border-top: none !important;

    &[data-clickable="true"] {
      cursor: pointer;
      user-select: none;
    }
  }

  .table {
    background-color: $body-bg;
  }

  tbody tr {
    @include border-y();
    &:hover {
      cursor: pointer;
      background-color: $table-row-hover-background-color;
    }
  }

  &.no-border {
    tbody tr {
      border: none;
    }
  }

  &__tooltip {
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &__tooltip-content {
    display: flex;
    flex-direction: column;
  }
}

.table-cell--action {
  float: right;
}

.table-header {
  display: flex;

  &__content {
    flex: 1;
  }

  &__icon {
    display: flex;
    align-items: center;
  }
}

.divTableRow .accordion {
  width: 100%;
}

.divTableRow {
  &__accordion-header {
    display: flex;
    margin-left: 40px;
    flex: 1;
  }
}

.table--ex {
  .divTableHeading {
    .divTableRow {
      padding-left: 40px;
    }
  }
}
