$heatmap-border-style: 1px solid $gray-200;
$heatmap-sidebar-width: 300px;
$heatmap-row-height: 38px;
$heatmap-sidebar-total-width: 60px;
.heatmap {
  display: flex;
  flex-direction: row;

  &--extended {
    .heatmap__sidebar-content {
      margin-top: 213px;
    }
  }
  &__sidebar {
    width: $heatmap-sidebar-width;
  }

  &__sidebar-content {
    margin-top: 102px;
    position: relative;
  }

  &__sidebar-row {
    height: $heatmap-row-height;
    width: 100%;
    display: flex;
    flex-direction: row;
    border: $heatmap-border-style;
    border-left: none;
    align-items: center;
    &--header {
      border: none;
      position: absolute;
      bottom: 100%;
      left: 0px;
      width: 100%;
      height: $heatmap-row-height;
    }
    &:hover {
      cursor: pointer;
    }
  }

  &__sidebar-title {
    flex: 1;
    &-p {
      width: $heatmap-sidebar-width - $heatmap-sidebar-total-width - 20;
    }
  }

  &__sidebar-total {
    width: $heatmap-sidebar-total-width;
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &--header {
      background-color: transparent;
    }

    &.heatmap__cell--green {
      background-color: rgba(theme-color("success"), 0.7);
    }

    &.heatmap__cell--amber {
      background-color: rgba(theme-color("warning"), 0.7);
    }

    &.heatmap__cell--red {
      background-color: rgba(theme-color("danger"), 0.7);
    }

    &.heatmap__cell--shaded {
      opacity: 0.1;
    }
  }

  &__content {
    flex: 1;
    overflow: auto;
  }
  &__header {
    display: flex;
    flex-direction: row;
    &--rotate {
      .heatmap__header--t {
        transform: rotate(90deg);
        transform-origin: center center;
      }
      .heatmap__header-child-title {
        padding-top: get-space(2);
        height: 150px;
        p {
          margin-top: get-space(3);
          width: 130px;
          text-align: left;
        }
      }
    }
  }

  &__header-group {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  &__header-group-title {
    padding: get-space(4);
    border: $heatmap-border-style;
    @include border-top-radius(4px);
    border-top: 3px solid theme-color();
  }

  &__header-group-childs {
    display: flex;
    flex-direction: row;
  }

  &__header-child-title {
    width: 50px;
    position: relative;
    display: block;
    border: $heatmap-border-style;
    padding: get-space(2);
  }

  &__body {
    position: relative;
  }

  &__row {
    position: relative;
    height: $heatmap-row-height;
  }

  &__cell {
    height: $heatmap-row-height;
    width: 50px;
    display: inline-flex;
    border: $heatmap-border-style;
    align-items: center;
    justify-content: center;
    transition: background-color 0.4s cubic-bezier(0, 0, 0, 1);
    &:hover {
      cursor: pointer;
    }

    &--green {
      background-color: rgba(theme-color("success"), 0.4);
      &:hover {
        background-color: rgba(theme-color("success"), 0.7);
      }
    }

    &--amber {
      background-color: rgba(theme-color("warning"), 0.4);
      &:hover {
        background-color: rgba(theme-color("warning"), 0.7);
      }
    }

    &--red {
      background-color: rgba(theme-color("danger"), 0.4);
      &:hover {
        background-color: rgba(theme-color("danger"), 0.7);
      }
    }

    &--shaded {
      opacity: 0.3;
    }
  }
}
