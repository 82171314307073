$inner-nav-content-nav-background-color: $gray-100 !default;
$inner-nav-content-nav-item-active-bg-color: $white !default;

.inner-nav-content {
    display: flex;
    flex-direction: row;
    min-height: 100%;

    &__block {
        flex: 1;
        &--nav {
            background-color: $inner-nav-content-nav-background-color;
            width: 200px;
            padding: get-space(4);
            flex: none;
            padding-top: 190px;

            &.navigation-visualisation{
                padding-top: 24px;
            }
        }
    }

    &__list {
        padding-left: 0px;
        margin: 0px;
        list-style: none;
    }

    &__item {
        padding: get-space(2) get-space(3);
        @include border-radius(4px);
        @include transition();
        &--active,
        &:hover {
            background-color: $inner-nav-content-nav-item-active-bg-color;
            cursor: pointer;
        }
    }
}
