$main-font-path: "../fonts";
@import "./consts/fonts";
@import "../../node_modules/@flightpath/coreui/dist/sass/coreui.scss";

// $mdi-font-path: "/fonts/mdi-icons";
// @import "./../node_modules/@mdi/font/scss/materialdesignicons";

@import "./consts/colors";
@import "./consts/functions";
@import "./consts/variables";

@import "./consts/normalise";
@import "./mixins/mixins";

@import "./consts/grid";
@import "./consts/typography";
@import "./consts/utils/utils";
@import "./common/common";

@import "./pages/forms/project/project";
@import "./pages/pages";

pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

code {
  font-family: Courier, "New Courier", monospace;
  font-size: 12px;
}
